import styled from 'styled-components';
import { customMediaQuery } from 'students/views/shared/styled';
import ButtonGeneral from '../../ButtonGeneral';

export const SHeaderButton = styled(ButtonGeneral)`
  background: transparent;
  border-radius: 50%;

  ${customMediaQuery('tablet')} {
    svg {
      width: 40px;
      height: 42px;
    }
  }
`;
